import React,{useState,useEffect,useContext } from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import { LocationContext }  from './services/Location';
import RoutesConfig from './routes/RoutesConfig';
import { useSocket } from './context/SocketProvider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import "./App.css";
const App = () => {
 
  const socket = useSocket();
 
  const { isLocationEnabled, enableLocation } = useContext(LocationContext);
  const [locationData, setLocationData] = useState(null);
  const [open, setOpen] = useState(false);
 

  
  const handleEnableLocation=async()=>{
    await enableLocation();
    getLocation();
  }
  const handleClose = () => {
    setOpen(false);
    window.location.href = "/agents/login";
   
  };
  useEffect(() => {
    const checkLocation = async () => {
      if (isLocationEnabled === false) {
       // setOpen(true);
      } else {
        getLocation();
       // setOpen(false);
      }
    };
  
    checkLocation();
  }, [isLocationEnabled]);

  

  useEffect(() => {

      
    const handleCheckStatus = (status) => {
 
     let token = localStorage.getItem("agent_isToken");

     
     if (token && token.startsWith("agentanshPay ")) {
      token = token.replace("agentanshPay ", ""); 
    }
      // Check if token exists and remove "agentanshPay "
      if (token && token.startsWith("agentanshPay ")) {
          token = token.replace("agentanshPay ", ""); 
      }
     
      if(
        status?.mobile == localStorage.getItem("agent_mobile") &&
        (status.secondtime != token || !token)  && localStorage.getItem("agent_isToken")
      ) {
      // alert("commmm")
        setOpen(true);
        localStorage.removeItem("agent_isKyc");
        localStorage.removeItem("agent_mobile");
        localStorage.removeItem("agent_isLogin");
        localStorage.removeItem("agent_isToken");
        localStorage.removeItem("agent_isRole");
        localStorage.removeItem("agent_isType");
        localStorage.removeItem("agent_logindate");
        localStorage.removeItem("agent_location");
      }
      else{ //alert("notcom")
     
        console.log('No Data from server user status:',status.refresh_Token);
      }
      
    };

    socket.on('checkstatus',handleCheckStatus);
    return () => {
      socket.off('checkstatus', handleCheckStatus);
     //  socket.disconnect();
     
    };
  }, [socket]);

  const getLocation = () => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocationData({ latitude, longitude });
          },
          (error) => {
            console.error('Error getting location:', error.message);
          }
        );
      } else {
        console.error('Geolocation is not supported by your browser.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // useEffect(() => {
  //   console.log(window.location.pathname.startsWith('/agents'))
  //   const handleWindowClose = (event) => {
  //     // Customize the confirmation message as needed
  //     const confirmationMessage = 'Kya aap sach mein yeh tab ya browser band karna chahte hain?';

  //     event.preventDefault();
  //     event.returnValue = confirmationMessage; // For Chrome
  //     return confirmationMessage; // For other browsers
  //   };

  //   window.addEventListener('beforeunload', handleWindowClose);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleWindowClose);
  //   };
  // }, []);

  
  return (
   <>
   
    <Provider store={store}>

           <Router>
           <RoutesConfig />
          </Router>
     
    </Provider> 
    {/* {window.location.pathname.startsWith('/agents') && open && ( */}
<React.Fragment>

<Dialog
  open={open}
  onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">
    {"Logout"}
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
     You are logged out!
    </DialogContentText>
  </DialogContent>
  <DialogActions>

    <Button onClick={handleClose} autoFocus>
      Close
    </Button>
  </DialogActions>
</Dialog>
</React.Fragment>
    {/* )} */}
</>
  );
};

export default App;
