  
  // export const SERVICE_URL = "/app";
  export const IS_Login = localStorage.getItem("admin_isLogin") === "true" ? true : false;
  export const IS_AUTH_GUARD_ACTIVE = true;
  export const ADMIN_SERVICE_URL ="https://anshpe.com/admin/api/v1";//"http://localhost:8000/admin/api/v1"//
  export const AGENT_SERVICE_URL ="https://anshpe.com/agent/api/v1";//"http://localhost:8000/agent/api/v1"//
  export const DEFAULT_TOKEN = 'anshPay eyJhbGciOiJSUzI1NiIsImtpZCI6ImtleS1yczI1NiIsInR5cCI6IkpXVCJ9';
  export const DEFAULT_AGENTTOKEN = 'agentanshPay eyJhbGciOiJSUzI1NiIsImtpZCI6ImtleS1yczI1NiIsInR5cCI6IkpXVCJ9';
  export const IMAGE_URL ="https://anshpe.com/uploads/";//"http://localhost:8000/uploads/"//
  // For detailed information: https://github.com/nfl/react-helmet#reference-guide
  export const REACT_HELMET_PROPS = {
    defaultTitle: "Ansh Pays",
    titleTemplate: "Ansh Pays",
  };
  
  
  export const DEFAULT_PATHS = {
    APP: "/",
    LOGIN: "/login",
    REGISTER: "/register",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    USER_WELCOME: "/dashboards/default",
    NOTFOUND: "/page-not-found",
    UNAUTHORIZED: "/unauthorized",
    INVALID_ACCESS: "/invalid-access",
  };
  
  